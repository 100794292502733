import { API_URL } from "../../config";
import { axios } from "../../lib/axios";
import { EmailSubscribe, EmailSubscribeResponse,  } from "./interface";



const getDocumentStatus = async (values: any): Promise<any> => {
  return axios.get(`${API_URL}/bundle/showDocuments?latitude=${values?.latitude}&longitude=${values?.longitude}`);
};

const addOrder = async (values: any, token :string): Promise<any> => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return axios.post(`${API_URL}/order`, { ...values, isNiyo: true }, config);
};

export {getDocumentStatus, addOrder };
